import request from '@/utils/axios'

/** 获取用户列表 */
export function getList(params) {
  return request({
    url: '/customer/list',
    method: 'get',
    params
  })
}

/** 获取用户详情 */
export function getDetail(id) {
  return request({
    url: `/customer/${id}`,
    method: 'get'
  })
}

/** 添加用户 */
export function add(data) {
  return request({
    url: '/customer',
    method: 'post',
    data
  })
}

/** 编辑用户 */
export function edit(data) {
  return request({
    url: '/customer',
    method: 'put',
    data
  })
}

/** 设置推荐 */
export function recommend(id) {
  return request({
    url: `/customer/set/recommend/${id}`,
    method: 'get'
  })
}

/** 取消推荐 */
export function noRecommend(id) {
  return request({
    url: `/customer/cancel/recommend/${id}`,
    method: 'get'
  })
}

/** 设置徽章 */
export function setBadge(data) {
  return request({
    url: '/customer/keep/badge',
    method: 'post',
    data
  })
}

/** 设置高能经纪人 */
export function setCustomer(data) {
  return request({
    url: '/customer/keep/customer/high',
    method: 'post',
    data
  })
}

/** 获取城市列表 */
export function getCityList() {
  return request({
    url: '/area',
    method: 'get'
  })
}

// 获取高能经纪人列表
export function getHeightList(params) {
  return request({
    url: '/customer/high/list',
    method: 'get',
    params
  })
}

/** 获取徽章列表 */
export function getBadgeList(params) {
  return request({
    url: '/badge',
    method: 'get',
    params
  })
}

/** 获取会话列表 */
export function getMessageList(data) {
  return request({
    url: '/dialog/get/new/message/list',
    method: 'post',
    data
  })
}

/** 获取用户会话列表 */
export function getUserMessageList(data) {
  return request({
    url: '/dialog/dialog/list',
    method: 'post',
    data
  })
}

/** 获取会话消息列表 */
export function getNewsList(data) {
  return request({
    url: '/dialog/get/dialog/message/list',
    method: 'post',
    data
  })
}

/** 发送聊天 */
export function sendContent(data) {
  return request({
    url: '/dialog/send/message',
    method: 'post',
    data
  })
}
