<template>
  <el-row type="flex" justify="space-between" style="height:100%;padding:0 40px 0 0">
    <div style="display: flex;align-items:center;">
      <img class="logo" src="@/static/images/logo.jpg" alt="">
      <h1 class="sidebar-title">高金兼联盟</h1>
    </div>
    <el-row type="flex" align="middle">
      <div style="font-size:24px;cursor:pointer;margin-right:10px;">{{ username }}</div>
      <div style="cursor:pointer;" @click="back">退出</div>
    </el-row>
  </el-row>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  computed: {
    username() {
      return sessionStorage.getItem('username') || ''
    }
  },
  methods: {
    back() {
      this.$confirm('确定要退出登录吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        sessionStorage.removeItem('access_token')
        this.dialogVisible = false
        this.$router.push({
          path: '@/views/login'
        })
        this.$message({
          type: 'success',
          message: '您已退出登录!'
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar-title {
  line-height: 80px;
  color: #dbb68d;
}
.logo {
  width: 60px;
  height: 60px;
}
</style>
