import axios from 'axios'
import nProgress from 'nprogress'
import { Message, MessageBox } from 'element-ui'
import router from '../router'

const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API
})

request.interceptors.request.use((config) => {
  config.headers['Token'] = sessionStorage.getItem('access_token') || ''
  nProgress.start()
  return config
})

request.interceptors.response.use((response) => {
  if (response.data instanceof Blob) {
    return Promise.resolve(response.data)
  }
  if (response.data.code === 200) {
    return Promise.resolve(response.data)
  } else if (response.data.code === 401) {
    MessageBox.confirm('登录已过期, 是否重新登录?', '提示')
      .then(() => {
        sessionStorage.removeItem('access_token')
        router.replace('/login')
      })
      .catch(() => {
        MessageBox.close()
      })
  } else {
    Message.error(response.data.msg)
    return Promise.reject(new Error(response.data))
  }
},
(error) => {
  nProgress.done()
  Message.error('网络异常')
  return Promise.reject(error)
})

export default request
