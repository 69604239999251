import request from '@/utils/axios'

/** 登录 */
export function ApiLogin(data) {
  return request({
    url: '/admin/login',
    method: 'post',
    data
  })
}
