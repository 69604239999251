<template>
  <el-menu class="el-menu-vertical-demo menu" active-text-color="#ffd04b" router :collapse="isCollapse" unique-opened background-color="#545c64" text-color="#fff" style="width:220px;height: calc(100vh - 80px)">
    <sidebar-item v-for="(router, index) in siderList" :key="index" :router="router" />
  </el-menu>
</template>

<script>
import { getSiderList } from '@/api/sider'
import SidebarItem from './components/SidebarItem.vue'
export default {
  components: { SidebarItem },
  data() {
    return {
      isCollapse: false,
      siderList: [],
      show: true
    }
  },
  created() {
    this.getSiderList()
  },
  methods: {
    change() {
      this.isCollapse = !this.isCollapse
      this.show = !this.show
    },
    getSiderList() {
      getSiderList().then(res => {
        this.siderList = res.data.list
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .nav {
    min-width: 63px;
    .menu {
      height: calc(100vh - 80px);
    }
    .el-menu-vertical-demo:not(.el-menu--collapse) {
      width: 200px;
    }
  }
</style>
