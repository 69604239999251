<template>
  <el-container>
    <el-header style="height:80px;border-bottom: 1px solid #eee;padding:0;">
      <Header />
    </el-header>
    <el-container>
      <el-aside class="aside" width="initial">
        <Sider />
      </el-aside>
      <el-main class="main">
        <div v-for="(item, index) in messageList" :key="index" plain />
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import Sider from '@/components/Sider'
import Header from '@/components/Header'
import { getMessageList } from '@/api/user'
export default {
  components: { Sider, Header },
  data() {
    return {
      messageList: []
    }
  },
  created() {
    this.$router.push({
      path: '/banner'
    })
    this.getNewMessage()
  },
  // beforeCreate() {
  //   const routes = JSON.parse(sessionStorage.getItem('menuList') || '[]')
  //   if (routes.length) {
  //     this.$router.addRoutes(routes)
  //   }
  // }
  methods: {
    getNewMessage() {
      getMessageList({ page: 1, pageSize: 5 }).then(res => {
        this.messageList = res.data.list
        this.messageList.forEach(item => {
          this.$notify({
            iconClass: 'el-icon-chat-dot-round',
            title: '新消息',
            message: `<div style="display: flex;align-items: center;"><img style="width: 30px;height:30px;border-radius:50%;margin-right:10px;" src="${item.recverCustomer.headUrl}" alt="">收到了新消息</div>`,
            dangerouslyUseHTMLString: true,
            duration: 0,
            onClick: () => {
              this.$router.push({
                path: '/user'
              })
            }
          })
        })
      })
    }
  }
}
</script>

<style lang="scss">
  .aside {
    height: calc(100vh - 80px);
  }
  .aside::-webkit-scrollbar{width:0;}
  .main {
    height: calc(100vh - 80px);
  }
  .el-notification:nth-of-type(2) {
    top: 16px !important;
  }
  .el-notification:nth-of-type(3) {
    top: 120px !important;
  }
  .el-notification:nth-of-type(4) {
    top: 220px !important;
  }
  .el-notification:nth-of-type(5) {
    top: 320px !important;
  }
  .el-notification:nth-of-type(6) {
    top: 420px !important;
  }
</style>
