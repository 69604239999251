<template>
  <el-card class="login-panel">
    <el-row slot="header" class="header" type="flex" justify="center">
      高金兼联盟
    </el-row>
    <el-form ref="form" class="form" :model="loginForm" :rules="rules" label-width="auto">
      <el-form-item prop="username" label="账号">
        <el-input v-model="loginForm.username" />
      </el-form-item>
      <el-form-item prop="password" label="密码">
        <el-input v-model="loginForm.password" type="password" show-password />
      </el-form-item>
      <el-row type="flex" justify="center">
        <el-button class="submit_btn" type="primary" @click="submit">登录</el-button>
      </el-row>
    </el-form>
  </el-card>
</template>

<script>
import { ApiLogin } from '@/api/login'
export default {
  data() {
    return {
      rules: {
        'username': [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],
        'password': [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ]
      },
      loginForm: {}
    }
  },
  mounted() {
    var el_message_box = document.querySelector('.el-message-box__wrapper')
    var v_modal = document.querySelector('.v-modal')
    if (el_message_box && v_modal) {
      el_message_box.parentNode.removeChild(el_message_box)
      v_modal.parentNode.removeChild(v_modal)
    }
  },
  methods: {
    submit() {
      this.$refs.form.validate().then(() => {
        ApiLogin(this.loginForm)
          .then((res) => {
            sessionStorage.setItem('access_token', res.data.Token)
            this.$message.success('登录成功')
            this.$router.replace('/')
          })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .login-panel {
    width: 600px;
    height: 350px;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border: 1px solid #ccc;
    border-radius: 10Px;
    box-shadow: 0 2Px 4Px rgb(0 0 0 / 12%), 0 0 6Px rgb(0 0 0 / 4%);
    .form {
      padding: 40px 20px 0 20px;
      .submit_btn {
        margin-top: 20px;
        width: 100%;
      }
    }
  }
</style>
